<template>
  <section id="media">
    <b-card>
      <b-row class="mb-2">
        <b-col
          md="9"
          style="text-align: left;"
        >
          <h1>{{ $t("media.mediaTitle") }}</h1>
          <h5 class="text-primary">
            {{ $t("media.mediaSubtitle") }}
          </h5>
        </b-col>
        <b-col
          style="text-align: right;"
        >
          <b-button
            :disabled="selected ? false : true"
            style="text-align: end;"
            variant="success"
            @click="$emit('files', selected)"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col md="10">
          <b-form-input
            v-model="search"
            :placeholder="$t('Search')"
            @keyup="inputSearch($event)"
          />
        </b-col>
        <b-col style="text-align: right;">
          <b-button
            variant="primary"
            @click="searching"
          >
            {{ $t('Search') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-wrap">
        <b-col>
          <b-table
            :striped="true"
            sticky-header="500px"
            :selectable="true"
            :select-mode="'single'"
            :no-border-collapse="true"
            responsive="sm"
            :items="searchFiles"
            :fields="columns"
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              <b-row style="align-items: center;">
                <b-col
                  v-if="data.item.node.type == 'IMG'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    class="embed-responsive-item cursor-pointer"
                    :src="data.item.node.fileUrl"
                    style="object-fit: contain;"
                    loading="lazy"
                    alt="image"
                    @error="errorImg"
                  />
                </b-col>
                <b-col
                  v-if="data.item.node.type == 'VID'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <video
                    id="demo"
                    class="video-js vjs-default-skin vjs-big-play-centered tamanio cursor-pointer"
                    data-setup="{&quot;liveui&quot;: true}"
                    preload="metadata"
                    :src="data.item.node.fileUrl"
                  />
                </b-col>
                <b-col
                  v-if="data.item.node.type == 'AUD'"
                  cols="3"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <b-img
                    class="embed-responsive-item cursor-pointer"

                    :src="require('@/assets/images/icons/audio.svg')"
                    loading="lazy"
                    alt="image"
                  />
                </b-col>

                <b-col id="acortarPadre">
                  <h5 id="acortar">
                    {{ data.item.node.name }}
                  </h5>
                </b-col>
                <b-form-file
                  id="changeFile"
                  v-model="changeFile"
                  class="d-none"
                  accept="image/jpeg, image/png, audio/mp3,video/mp4"
                />
              </b-row>
            </template>
            <template #cell(type)="data">
              <div class="text-nowrap">
                {{
                  data.item.node.type == "VID"
                    ? "Vídeo"
                    : data.item.node.type == "AUD"
                      ? "Audio"
                      : "Imagen"
                }}
              </div>
            </template>

            <template #cell(size)="data">
              <div class="text-nowrap">
                <span :id="data.item.node.id">
                  ------
                </span>
              </div>
            </template>
            <template #cell(time)="data">
              <div class="text-nowrap">
                {{
                  data.item.node.type == "IMG"
                    ? "------------"
                    : formatTime(data.item.node.mediaDuration)
                }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-nowrap">
                {{ formatDate(data.item.node.createdAt) }}
              </div>
            </template>
          </b-table>

          <b-overlay
            :show="busy"
            no-wrap
            variant="transparent"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <template #overlay>
              <div
                v-if="processing"
                class="text-center p-4 bg-light "
              >
                <feather-icon
                  style="color:black"
                  icon="DownloadIcon"
                  size="15px"
                />
                <h1 style="color:black">
                  Download...
                </h1>
                <b-progress
                  min="0"
                  :max="totalContadorDescarga"
                  :value="recibiendoDescarga"
                  variant="dark"
                  height="11px"
                  class="mx-n4 rounded-0"
                />
              </div>
              <div
                v-else
                ref="dialog"
                tabindex="-1"
                role="dialog"
                aria-modal="false"
                aria-labelledby="form-confirm-label"
                class="text-center p-3"
              >
                <h1
                  style="color:white"
                  class="mb-5"
                >
                  <strong id="form-confirm-label">{{ $t("code.title") }}</strong>
                </h1>
                <div class="d-flex">
                  <b-button
                    variant="success"
                    size="lg"
                    @click="onOK"
                  >
                    OK
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-3"
                    size="lg"
                    @click="onCancel"
                  >
                    {{ $t("dataGeneric.cancel") }}
                  </b-button>
                </div>
              </div>
            </template>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex ">
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="fetchData()"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="my-modal"
      size="lg"
      hide-footer
      :title="$t('media.viewFile')"
    >
      <div class="d-block text-center">
        <view-file-modal
          :datos="datosObject"
          :update-file="boolFile"
          :upload-data="uploadData"
          :progress="uploadData.progress"
        />
      </div>
    </b-modal>
  </section>
</template>

<script>
import * as constants from '@core/utils/constants'
import {
  BCard,
  BCol,
  BRow,
  BTable,
  VBTooltip,
  BButton,
  BFormInput,
  BProgress,
  BPagination,
  BImg,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { isUserInGroup, messageError, utf8ToB64 } from '@/store/functions'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { getUserData } from '@/auth/utils'
import ViewFileModal from './ViewFileModal.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BImg,
    BCard,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BTable,
    ViewFileModal,
    BProgress,
    BPagination,
    BFormFile,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    typeFile: {
      default: '',
      type: String,
    },

  },
  data() {
    return {
      tipo: 'VID',
      overlay: false,
      changeFile: null,
      idEditFile: null,
      boolFile: false,
      destRes: null,
      userData: getUserData(),
      idCont: null,
      idfile: null,
      initialRes: false,
      contentResourceSelected: null,
      type: 0,
      interactivitieResource: [],
      contentResource: [],
      search: '',
      files: null,
      searchFiles: null,
      inputFolderName: '',
      selected: null,
      inputFolderDescription: '',
      uploadFilesList: [],
      datosObject: null,
      fileInput: null,
      dataFileInput: [],
      subiendo: false,
      contentResourcesListForUpdate: [],
      resInfo: null,
      sponsor: false,
      busy: false,
      processing: false,
      counter: 1,
      interval: '',
      dataOverlay: '',
      onHidden: false,
      totalContadorDescarga: 0,
      recibiendoDescarga: 0,
      aud: 0,
      vid: 0,
      img: 0,
      rows: 0,
      perPage: 20,
      currentPage: 1,
      uploadData: {
        key: null,
        file: null,
        duration: 0,
        policy: {},
        progress: null,
      },
      columns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
        { key: 'time', label: this.$t('contents.Duration') },
        { key: 'fecha', label: this.$t('dateUp') },
      ],
    }
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.sponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.loadData()
  },
  methods: {
    onRowSelected(item) {
      this.selected = item[0] ? item[0].node : null
    },

    inputSearch(event) {
      const keycode = event.keyCode || event.which

      if (keycode === 13) this.searching()
    },
    loadData() {
      this.searching()
    },
    searching() {
      this.currentPage = 1
      this.fetchData()
    },

    onCancel() {
      this.busy = false
    },

    onOK() {
      this.totalContadorDescarga = 0
      this.recibiendoDescarga = 0
      this.downloadFile(this.dataOverlay)
      this.processing = true
    },
    fetchData() {
      axios
        .post('', {
          query: `
            query {              
              allFiles(
              first: ${this.perPage},
              offset: ${this.perPage * (this.currentPage - 1)},
              name: "${this.search}", 
              type: "${this.typeFile}"                          
              client:"${this.userData.profile.client.id}"
              ${this.sponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
              ) {
              totalCount
                edges {
                  node {
                    id
                    name
                    type
                    staticUrl
                    createdAt
                    mediaDuration
                    fileUrl
                    
                  }
                }
              }
            }

        `,
        })
        .then(response => {
          messageError(response, this)

          this.files = response.data.data.allFiles
            ? response.data.data.allFiles.edges
            : []

          this.rows = response.data.data.allFiles.totalCount

          this.searchFiles = this.files
        })
        .catch(res => {
          console.log(res)
        })
    },

    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl() {
      return noCover
    },
    formatDate(value) {
      const subscriptionDate = new Date(value)
      const newDate = subscriptionDate.toLocaleDateString('en-GB')
      return newDate
    },
    formatTime(duration) {
      return new Date(duration * 1000).toISOString().slice(11, 19)
    },

  },
}
</script>

<style scoped>
#media .dropContainer {
  border: 3px dashed;
  height: 100%;
}

#media .card-width {
  width: 200px;
}

#media .FileWidth {
  width: 150px;
}

#media .header {
  position: static;
  width: 100%;
  text-align: center;
  align-items: baseline;
}

#media .svg {
  margin: auto;
  z-index: 100;
}

#media .color {
  background-color: #000000 !important;
}

#media .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#media .actionsFooter {
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  left: 0;
}

#media .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#media .card-footer .actionsFooter {
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}

#media .actionFooter {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: -webkit-center;
}

#media .card-footer {
  border-top: 0;
}

#media .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#media .card-body h4 {
  font-size: 1.286rem !important;
}

#media .card .card-title {
  margin-top: 6rem !important;
  font-weight: bold;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
}

.head {
  display: flex;
  flex-direction: column;
}
</style>
